import React, { useEffect, useState } from "react"
import { decode } from "html-entities"

import { ImageDataLike, StaticImage } from "gatsby-plugin-image"

import Button from "@material-ui/core/Button"
import Container from "@ecom/ui/components/Container"
import clsx from "clsx"
import { Img } from "./Img"
import { ImgWithUtm } from "./ImgWithUtm"
import { getUTMParams } from "./helpers/getUTMParams"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

export type BannerProps = {
  withButton?: boolean
  html: string
  large: ImageDataLike
  mob: ImageDataLike
  isSavingsButton?: boolean
  otherButtonText?: boolean
  orderNum?: string
  nakopitelnye?: boolean
  variant?: "default" | "nakopitelnye"
}

export default function Banner({
  withButton = true,
  html,
  large,
  mob,
  nakopitelnye = false,
  isSavingsButton,
  otherButtonText,
  orderNum,
  variant = "default",
}: BannerProps) {
  const [bannerImgWithUTM, setBannerImgWithUTM] = useState(false)
  const text = { __html: decode(html) }

  // Поменять изображение на баннера при передаче UTM-меток
  // :TODO найти лучшее решение а не через дом менять и без белого экрана
  useEffect(() => {
    if (getUTMParams()) {
      setBannerImgWithUTM(true)
      document.body.style.display = "block"
    }
  }, [])

  return (
    <section className={styles.banner} data-exclude={orderNum}>
      {nakopitelnye ? (
        <></>
      ) : (
        <>
          {bannerImgWithUTM ? (
            <ImgWithUtm className={styles.imgOuter} alt="bg image" />
          ) : (
            <Img className={styles.imgOuter} alt="bg image" large={large} mob={mob} />
          )}
        </>
      )}
      <Container
        className={
          isSavingsButton ? clsx(styles.container, styles.savingsContainer) : styles.container
        }
      >
        {nakopitelnye ? (
          <>
            <StaticImage src="./img/jkx.png" className={styles.img} alt="" />
          </>
        ) : (
          <></>
        )}
        <div className={clsx(styles.textBlock, styles[variant])} dangerouslySetInnerHTML={text} />
        {withButton && (
          <div className={styles.btnOuter}>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {nakopitelnye ? (
                <>Заказать консультацию</>
              ) : (
                <>{otherButtonText ? "Получить карту" : "Заполнить Заявку"}</>
              )}
            </Button>
          </div>
        )}
      </Container>
    </section>
  )
}
